import { TextField } from "@mui/material";
import React from "react";
import jsStyles from "../styles/jsStyles";
import { validateByRules } from "../utils/validators";

function AppInput({
  label,
  value,
  setValue,
  validationRules: ruleTypes = "",
  showError,
  sx = {},
  ...props
}) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [validationFrequency, setValidationFrequency] =
    React.useState("ON_BLUR");

  React.useEffect(() => {
    if (showError) {
      validate(value);
      setValidationFrequency("ON_CHANGE");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError]);

  function onChange({ target: { value } }) {
    setValue(value);

    if (!ruleTypes || (validationFrequency !== "ON_CHANGE" && !showError))
      return;
    validate(value);
  }

  function onBlur({ target: { value } }) {
    if (!ruleTypes || (validationFrequency !== "ON_BLUR" && !showError))
      return;
    validate(value);
    setValidationFrequency("ON_CHANGE");
  }
  function validate(value) {
    if (!ruleTypes.trim()) return; // Skip validation if no rules
    const errorMessage = validateByRules(ruleTypes, value);
    setErrorMessage(errorMessage);
  }

  return (
    <TextField
      fullWidth
      sx={{ ".MuiOutlinedInput-root": jsStyles.textField, ...sx }}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      {...props}
    />
  );
}

export default AppInput;
