import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.scss";
import "./plugins/firebase";
import theme from "./context/theme";
import { RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import { UserContextProvider } from "./context/user";
import { ProjectsStatusContextProvider } from "./context/projectsStatus";
import { ProjectsFiltersContextProvider } from "./pages/projects/hooks/useFilteredProjects";
import router from "./router";
import { ChatProvider } from "./hooks/useChat";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ConfirmProvider
          defaultOptions={{
            title: "Are you sure?",
            confirmationText: "Delete",
            cancellationText: "Cancel",
            confirmationButtonProps: {
              variant: "contained",
              color: "error",
            },
          }}
        >
          <SnackbarProvider
            autoHideDuration={3000}
            iconVariant={{ error: "" }}
            variant="success"
            defaultValue={"An error has occurred"}
            disableWindowBlurListener
          >
            <div className="app-layout">
              <ProjectsStatusContextProvider>
                <ChatProvider>
                  <UserContextProvider>
                    <ProjectsFiltersContextProvider>
                      <RouterProvider router={router} />
                    </ProjectsFiltersContextProvider>
                  </UserContextProvider>
                </ChatProvider>
              </ProjectsStatusContextProvider>
            </div>
          </SnackbarProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
