import { Box } from "@mui/material";
import React from "react";
import TeamMember from "./TeamMember";
import { useState } from "react";

function Team({
  team,
  showEmptyPositions,
  userIsAllowedToEdit,
  onConfirmRemoveMember,
  ...props
}) {
  const [popupState, setPopupState] = useState({
    memberId: null,
    anchorEl: null,
  });

  const handlePopupOpen = (memberId, anchorEl) => {
    setPopupState({
      memberId: memberId || null,
      anchorEl: anchorEl || null,
    });
  };

  const handlePopupClose = () => {
    setPopupState({
      memberId: null,
      anchorEl: null,
    });
  };

  const teamItems = showEmptyPositions
    ? team
    : team?.filter(({ chosenUser }) => chosenUser);
  if (showEmptyPositions)
    teamItems.sort((a, b) =>
      a.chosenUser && !b.chosenUser ? -1 : !a.chosenUser && b.chosenUser ? 1 : 0
    );

  return (
    <Box display="flex" flexWrap="wrap" gap={1} {...props}>
      {teamItems?.map(({ chosenUser, requirements, _id }) => (
        <TeamMember
          key={_id}
          showEmptyPosition
          userIsAllowedToEdit
          popupState={popupState || { memberId: null, anchorEl: null }}
          onPopupOpen={handlePopupOpen}
          onPopupClose={handlePopupClose}
          onConfirmRemoveMember={() => onConfirmRemoveMember(_id || undefined)}
          memberId={_id}
          user={chosenUser}
          role={requirements?.role}
        />
      ))}
    </Box>
  );
}

export default Team;
