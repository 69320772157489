import { Box, Typography } from "@mui/material";
import React from "react";
import { TECH_PRO_SIGNUP_STEPS } from "../../../data/lists";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import { Step4 } from "./steps/Step4";

function StepCard({ index }) {
  return (
    <Box display="flex" flexDirection="column" alignItems={"center"}>
      <Box display="flex" alignItems="center" gap={1.0} my={1.5} mb={3}>
        <Typography variant="h3">{TECH_PRO_SIGNUP_STEPS[index]}</Typography>
      </Box>
      {index === 0 && <Step1 />}
      {index === 1 && <Step2 />}
      {index === 2 && <Step3 />}
      {index === 3 && <Step4 />}
    </Box>
  );
}

export default StepCard;
