// AuthButton.jsx
import React from "react";

import { useNavigate } from "react-router-dom";
import GradientButton from "../../../components/GradientButton";
import { useUser } from "../../../context/user";

export function AuthUserButton({
  loggedOutLabel = "REQUEST TO JOIN!",
  loggedInLabel = "CHECK YOUR PROFILE!",
  loggedOutRedirect = "/signup",
  loggedInRedirect = "/my-profile",
  ...buttonProps
}) {
  const { user } = useUser();
  const navigate = useNavigate();
  const handleClick = () => {
    if (!user) {
      navigate(loggedOutRedirect);
    }
    navigate(loggedInRedirect);
  };
  return (
    <GradientButton
      label={!user ? loggedOutLabel : loggedInLabel}
      onClick={handleClick}
      {...buttonProps}
    />
  );
}
