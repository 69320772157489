import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { appFetch } from "../utils/fetch";
import { useUser } from "../context/user";
import MultipleCreatableInput from "./admin/MultipleCreatableInput";
import GradientButton from "./GradientButton";

function SkillsList({ isEditMode }) {
  const { user, setUser } = useUser();

  const skills = user.professionPrograms;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newSkills, setNewSkills] = useState(skills);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setNewSkills(user.professionPrograms);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewSkills(skills);
  };
  const handleOpenDialog = () => setIsDialogOpen(true);

  function createRemoveSkillHandler(id) {
    return async () => {
      try {
        const res = await appFetch("/api/user", {
          method: "PUT",
          body: JSON.stringify({
            professionPrograms: skills.filter(({ _id }) => _id !== id),
          }),
        });
        if (!res.ok) throw new Error();

        const data = await res.json();
        setUser({ ...user, ...data.user });
      } catch (err) {
        console.error(err);
      }
    };
  }

  async function addSkillsHandler() {
    try {
      setLoading(true);
      setError("");
      console.log(newSkills);
      const res = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify({
          professionPrograms: newSkills.map(({ _id }) => _id),
        }),
      });
      if (!res.ok) throw new Error();

      const data = await res.json();
      setUser({ ...user, ...data.user });
      handleCloseDialog();
    } catch (err) {
      setError("Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isEditMode ? (
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gap={0.5}
          sx={{ maxWidth: "500px" }}
        >
          {skills.map(({ name, _id }) => (
            <Chip
              key={_id}
              color="primary"
              label={name}
              variant="outlined"
              onDelete={createRemoveSkillHandler(_id)}
            />
          ))}
          <IconButton size="small" onClick={handleOpenDialog}>
            <AddIcon sx={{ fontSize: "1.1em" }} />
          </IconButton>
        </Box>
      ) : (
        skills.map(({ name }) => name).join(", ")
      )}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontWeight: 700, textAlign: "center" }}>
          Add skills
        </DialogTitle>
        <DialogContent sx={{ width: { xs: "80vw", sm: 450 } }}>
          <MultipleCreatableInput
            items={newSkills}
            setItems={setNewSkills}
            apiRoute="/api/mutable-list/programm-language"
            disabledCreation
            placeholder="Python, Xd..."
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <GradientButton
            label="SAVE"
            loading={loading}
            onClick={addSkillsHandler}
            sx={{ mb: 1 }}
          />
        </DialogActions>
        {error ? (
          <Typography color="error" fontWeight="700" mt={1}>
            {error}
          </Typography>
        ) : null}
      </Dialog>
    </>
  );
}

export default SkillsList;
