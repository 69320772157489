import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AppInput from "../../../../components/AppInput";
import GradientButton from "../../../../components/GradientButton";
import PlacesAutocomplete from "../../../../components/PlacesAutocomplete";
import { useUser } from "../../../../context/user";
import { appFetch } from "../../../../utils/fetch";
import { validateByRules } from "../../../../utils/validators";
import {
  swrFetcherNotAuth,
  swrFetcherNotAuth2,
} from "../../../../utils/fetch";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function Step2() {
  const { user, setUser } = useUser();
  const [phone, setPhone] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState(user?.linkedinURL || "");
  const [gitHubUrl, setGitHubUrl] = useState(user?.gitHubUrl || "");
  const [city, setCity] = useState(user?.city || null);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);
  const [isApproveEmail, setIsApproveEmail] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isApproveEmail) {
      const intervalId = setInterval(async () => {
        if (user.email) {
          try {
            const response = await swrFetcherNotAuth2(
              `/api/auth/check-already-approve-email/${user.email}`,
              { method: "GET" }
            );
            const resData = await response.json();
            if (resData.emailVerified) {
              setIsApproveEmail(true);
            }
          } catch (error) {
            console.error("Error checking email verification:", error);
          }
        }
      }, 3000);

      return () => clearInterval(intervalId);
    }
  }, [user.email, isApproveEmail]);

  const validateForm = () => {
    console.log("Validate has been fired");
    const gitHubError = gitHubUrl
      ? validateByRules("githubUrl", gitHubUrl)
      : null;

    const hasErrors =
      gitHubError ||
      validateByRules("required phone", phone) ||
      validateByRules("required linkedinUrl", linkedinUrl);
    return hasErrors;
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    const invalid = validateForm();
    if (invalid) {
      setShowInputErrors(true);
      return;
    }

    setServerError("");

    try {
      setLoading(true);
      //build payload
      const payload = {
        phone,
        url: { linkedin: linkedinUrl },
        city: { name: city?.description, placeId: city?.place_id },
      };
      // Add GitHub URL only if it's valid and not empty

      if (gitHubUrl) {
        payload.url.github = gitHubUrl;
      }
      console.log(payload);
      const res = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify(payload),
      });

      const data = await res.json();
      console.log(data);
      if (!res.ok) throw new Error(data.errorMessage);

      setUser(data.user);
    } catch (err) {
      setServerError(err.message || "Unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
  const resendVerificationEmail = async () => {
    setResendSuccess(false);
    if (user.email) {
      try {
        const res = await swrFetcherNotAuth2("/api/auth/check-email", {
          method: "POST",
          body: JSON.stringify({ email: user.email }),
        });
        console.log(res);
        const data = await res.json();

        if (!res.ok || data.message !== "Verification email sent.")
          throw new Error(data.errorMessage || "Server error");
        setResendSuccess(true);
      } catch (err) {
        console.log(err);
      } finally {
      }
    }
  };
  const handleClick = () => {
    console.log("User Skipped registration.");
    navigate("/");
  };
  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="Phone number"
            name="phone"
            value={phone}
            setValue={setPhone}
            validationRules={"required phone"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="LinkedIn URL"
            name="LinkedInUrl"
            value={linkedinUrl}
            setValue={setLinkedinUrl}
            validationRules={"required linkedinUrl"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppInput
            label="GitHub URL (optional)"
            name="GitHubUrl"
            value={gitHubUrl}
            setValue={setGitHubUrl}
            validationRules={"githubUrl"}
            showError={showInputErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PlacesAutocomplete
            place={city}
            setPlace={setCity}
            showError={showInputErrors}
          />
        </Grid>
      </Grid>
      {!isApproveEmail && (
        <Typography sx={{ mt: 2 }} style={{ color: "red" }}>
          Please confirm your email address before continuing <br />
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#555",
            }}
            onClick={resendVerificationEmail}
          >
            resend email
          </span>
        </Typography>
      )}
      {resendSuccess && (
        <Typography sx={{ mt: 2 }} style={{ color: "green" }}>
          Confirmation email sent successfully.
        </Typography>
      )}
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={6}>
          <GradientButton
            disabled={!isApproveEmail}
            sx={{ mt: 8 }}
            label="Next"
            type="submit"
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GradientButton
            onClick={handleClick}
            sx={{
              mt: 8,
              px: { xs: 2.2, sm: 3 }, // Padding for consistent size
              minWidth: { xs: "8rem", sm: "10rem" }, // Custom width
              background: "linear-gradient(90deg, #bbb, #888)", // Skip gradient
              ":hover": {
                background: "linear-gradient(90deg, #aaa, #777)", // Hover effect
              },
            }}
            label="Continue Later"
            type="button"
            useSingleColor={true} // Optional: ensures gradient matches
            singleColor="#888" // Optional: consistent with skip gradient
            labelProps={{
              color: "white", // Text color for the button label
            }}
            loading={loading}
          />
        </Grid>
      </Grid>
      {serverError ? (
        <Typography color="error" textAlign="center" height="1.5em">
          {serverError}
        </Typography>
      ) : null}
    </form>
  );
}

export default Step2;
