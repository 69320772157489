import * as React from "react";
import { Box, Typography, Stack, ClickAwayListener } from "@mui/material";
import { themeColors } from "../../utils/constant";
import GradientButton from "../GradientButton";

const RemoveMemberPopupContent = ({ onConfirmRemove, onClose }) => {
  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  const handleNoClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  const handleYesClick = (event) => {
    event.stopPropagation();
    onConfirmRemove();
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box
        onClick={handleContainerClick}
        sx={{
          bgcolor: "transparent",
          width: "max-content",
          maxWidth: "90vw",
          position: "relative",
          borderRadius: "22.22px",
        }}
      >
        <Stack
          spacing={0}
          alignItems="center"
          sx={{
            bgcolor: "transparent",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              bgcolor: "#fff",
              boxShadow: 1,
              border: "2px solid",
              borderColor: "#252654",
              borderRadius: "22.22px",
              padding: "32px 16px",
            }}
          >
            <Typography
              fontSize="24px"
              fontFamily="Outfit"
              fontWeight={600}
              pb="25px"
              align="center"
            >
              Remove this member?
            </Typography>
            <Stack direction="row" spacing="20px">
              <GradientButton
                label={"NO"}
                inverted
                invertedBgcolor={themeColors.indigo100}
                onClick={handleNoClick}
              />
              <GradientButton
                label={"YES"}
                invertedBgcolor={themeColors.indigo100}
                onClick={handleYesClick}
              />
            </Stack>
          </Box>
          <TriangleDown />
        </Stack>
      </Box>
    </ClickAwayListener>
  );
};

const TriangleDown = ({
  width = 30,
  height = 20,
  fillColor = "#fff",
  strokeColor = "#252654",
  strokeWidth = 2,
}) => {
  const topLeft = { x: 0, y: 0 };
  const topRight = { x: width, y: 0 };
  const bottomCenter = { x: width / 2, y: height };
  const points = `${topLeft.x},${topLeft.y} ${topRight.x},${topRight.y} ${bottomCenter.x},${bottomCenter.y}`;

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <polygon points={points} fill={fillColor} stroke="none" />
      <line
        x1={topLeft.x}
        y1={topLeft.y}
        x2={bottomCenter.x}
        y2={bottomCenter.y}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="square"
      />
      <line
        x1={topRight.x}
        y1={topRight.y}
        x2={bottomCenter.x}
        y2={bottomCenter.y}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="square"
      />
    </svg>
  );
};

export default RemoveMemberPopupContent;
