import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Stack, Typography } from "@mui/material";

import GradientTypography from "../../components/GradientTypography";
import jsStyles from "../../styles/jsStyles";
import { useUser } from "../../context/user";
import { calcSignupStepIndex } from "../../utils/auth";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { ReactComponent as TechIcon } from "./images/tech.svg";
// import { ReactComponent as NpoIcon } from "./images/npo.svg";
import { themeColors } from "../../utils/constant";

function SignupPage() {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center" }}>
      <Helmet>
        <title>vogo impact | signup</title>
      </Helmet>
      <GradientTypography mb={2} mt={8}>
        Sign up
      </GradientTypography>
      <Typography variant="h2" mb={5}>
        Join as a tech professional, showcase your skills, and get matched with
        task-based opportunities tailored just for you!
      </Typography>

      <Box
        display={{ xs: "flex" }}
        justifyContent="center"
        gap={{ xs: 0.7, sm: 4 }}
        mt={8}
        mb={20}
      >
        <UserTypeButton
          title="Tech Professional"
          subtitle="Looking to volunteer"
          icon={TechIcon}
          href="tech-professional"
        />
        {/* <UserTypeButton
          title="Organization"
          subtitle="Looking for skilled volunteers"
          icon={NpoIcon}
          href="/join-us"
        /> */}
      </Box>
    </Container>
  );
}

function UserTypeButton({ icon, title, subtitle, href }) {
  const navigate = useNavigate();
  const IconComponent = icon;
  return (
    <Stack
      sx={{
        ...jsStyles.cardBorder,
        borderColor: themeColors.indigo300,
        cursor: "pointer",
        ":hover": {
          borderColor: themeColors.indigo500,
        },
      }}
      width={{ xs: 260 }}
      height={{ xs: 200, sm: 260 }}
      onClick={() => navigate(href)}
      alignItems="center"
      justifyContent={{ xs: "center", sm: "end" }}
      pb={{ xs: 3, sm: 5 }}
      pt={{ xs: 3, sm: 0 }}
      px={0.5}
    >
      <IconComponent style={{ marginBottom: 30 }} />
      <Typography variant="h3">{title}</Typography>
      <Typography>{subtitle}</Typography>
    </Stack>
  );
}

export default SignupPage;
