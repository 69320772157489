import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import jsStyles from "../styles/jsStyles";

const PlacesAutocomplete = ({ setPlace, place, showError }) => {
  const {
    ready,
    value,
    suggestions: { data, loading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["(cities)"],
      componentRestrictions: { country: ["il", "usa"] },
    },
    debounce: 300,
  });

  const handleChangePlace = (event, selectedOption) => {
    setPlace(selectedOption);
  };

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  return (
    <Box>
      <Autocomplete
        fullWidth
        onChange={handleChangePlace}
        disabled={!ready}
        value={place} // Bind to the full selected option (not just description)
        options={data}
        isOptionEqualToValue={(option, value) => {
          return option?.place_id === value?.place_id;
        }}
        getOptionLabel={(option) => option?.description || ""}
        renderOption={(props, option) => (
          <li {...props}>{option.description}</li>
        )}
        filterOptions={(options, params) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            label="City"
            placeholder="Search..."
            error={showError && !place}
            helperText={showError && !place ? "City is required" : ""}
            value={value}
            onChange={handleInput}
            color="primary"
            sx={{ ".MuiOutlinedInput-root": jsStyles.textField }}
          />
        )}
      />
    </Box>
  );
};

export default PlacesAutocomplete;
