import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Link } from "react-router-dom";
import { RemoveCircleOutline } from "@mui/icons-material";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";

import { PROFESSION_ROLES } from "../data/lists";
import { themeColors } from "../utils/constant";
import { getFullName, getTextByValue } from "../utils/tools";
import AppAvatar from "./AppAvatar";
import jsStyles from "../styles/jsStyles";
import RemoveMemberPopupContent from "./popperPopup/RemoveMemberPopupContent";

const EmptyPositionAvatar = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "dashed 1px #aaa",
      borderRadius: 100,
      width: { xs: 35, sm: 45 },
      height: { xs: 35, sm: 45 },
    }}
  >
    <QuestionMarkIcon />
  </Box>
);

const RemoveMemberButton = ({ popperId, onClick, open }) => (
  <IconButton
    aria-describedby={popperId}
    onClick={onClick}
    sx={{ ml: "auto", mr: 1 }}
    size="small"
  >
    <RemoveCircleOutline sx={{ color: themeColors.orange }} />
  </IconButton>
);

const MemberInfo = ({ subtitle, user }) => (
  <div>
    <Typography color={themeColors.orange}>{subtitle}</Typography>
    <Typography variant="h4">{getFullName(user)}</Typography>
  </div>
);

const TeamMember = ({
  user,
  subtitle: subtitleProp = "",
  role = "",
  showEmptyPosition = false,
  userIsAllowedToEdit = false,
  onClickRemoveMember,
  popupState,
  onPopupOpen,
  onPopupClose,
  memberId,
  onConfirmRemoveMember,
  sx = {},
  isTask,
}) => {
  // compute derived state
  const isTeamMemberFilled = user !== undefined;
  const isClickable = user?.uid;
  const professionRole = user?.professionRole || role;
  const subtitle =
    subtitleProp || getTextByValue(PROFESSION_ROLES, professionRole);
  const isPopperOpen =
    popupState?.memberId === memberId && popupState?.anchorEl !== null;
  const removeButtonPopperId = isPopperOpen
    ? "remove-button-popper"
    : undefined;

  // event handlers
  const handleRemoveButtonClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (isPopperOpen) {
      onPopupClose();
    } else {
      onPopupOpen(memberId, event.currentTarget);
    }

    onClickRemoveMember?.();
  };

  const showRemoveButton = userIsAllowedToEdit && isTeamMemberFilled;

  // return component
  return (
    <Box
      display="flex"
      alignItems="center"
      className="clean-link"
      bgcolor={themeColors.indigo100}
      borderRadius={50}
      p={1}
      pr={showRemoveButton ? 1 : 6}
      gap={2}
      component={isClickable ? Link : "div"}
      sx={{
        transition: ".15s box-shadow",
        ":hover": isClickable ? jsStyles.shadowSm : {},
        width: "fit-content",
        ...sx,
      }}
      to={isClickable ? `/profile/${user?.uid}` : null}
    >
      {showEmptyPosition && !user ? (
        <EmptyPositionAvatar />
      ) : (
        <AppAvatar isTask={true} user={user} />
      )}

      <MemberInfo subtitle={subtitle} user={user} />

      {showRemoveButton && (
        <>
          <RemoveMemberButton
            popperId={removeButtonPopperId}
            onClick={handleRemoveButtonClick}
            open={isPopperOpen}
          />
          <Popper
            id={removeButtonPopperId}
            open={isPopperOpen}
            anchorEl={popupState?.anchorEl}
            placement="top"
            transition
            disablePortal={false}
            sx={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div>
                  <RemoveMemberPopupContent
                    onConfirmRemove={onConfirmRemoveMember}
                    onClose={onPopupClose}
                  />
                </div>
              </Fade>
            )}
          </Popper>
        </>
      )}
    </Box>
  );
};

export default TeamMember;
