import React, { useState } from "react";
import styles from "./Header.module.scss";
import { Divider, Menu, MenuItem, Typography, Zoom } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import AppAvatar from "../../AppAvatar";

import { useUser } from "../../../context/user";
import { fireAuth } from "../../../plugins/firebase";
import LogoSrc from "../../../images/logo.svg";
import { useProjectsStatus } from "../../../context/projectsStatus";
// import GradientButton from "../../GradientButton";
import { Box } from "@mui/system";
import { themeColors } from "../../../utils/constant";

import { ReactComponent as HomeIcon } from "../../../images/icons/home.svg";
import { ReactComponent as BagIcon } from "../../../images/icons/bag.svg";
import { ReactComponent as BellIcon } from "../../../images/icons/bell.svg";
import { ReactComponent as AdminPanelIcon } from "../../../images/icons/admin_panel.svg";
import GradientButton from "../../GradientButton";
import { USER_STATUSES } from "../../../data/lists";
import jsStyles from "../../../styles/jsStyles";
import NotificationsMenu from "../../NotificationsMenu";

import useSWR from "swr";
import { swrFetcher } from "../../../utils/fetch";

const PrivateHeader = () => {
  const { user } = useUser();
  const { projectsStatus } = useProjectsStatus();
  const location = useLocation();
  const navigate = useNavigate();

  const [usernameAnchorEl, setUsernameAnchorEl] = useState(null);
  const handleCloseUsernameMenu = () => setUsernameAnchorEl(null);
  const handleOpenUsernameMenu = (ev) => setUsernameAnchorEl(ev.currentTarget);

  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const handleCloseNotificationsMenu = () => setNotificationsAnchorEl(null);
  const handleOpenNotificationsMenu = (ev) =>
    setNotificationsAnchorEl(ev.currentTarget);

  const projectInProcess = projectsStatus.projectsInProcess[0];
  const userApproved = user?.status === "approved";
  const userConfigured = user?.status !== USER_STATUSES[0].value;
  const { data: notifications, mutate } = useSWR(
    userApproved ? "/api/notification" : null,
    swrFetcher
  );
  const thereIsUnreadNotification = notifications?.some((i) => !i.read);

  const tabs = [
    {
      label: user?.role === 2 ? "Home" : "Find projects",
      icon: HomeIcon,
      href: "/projects",
    },
    {
      label: "My Projects",
      icon: BagIcon,
      href: projectInProcess
        ? `/project/${projectInProcess._id}/status`
        : "/my-projects",
    },
    {
      label: "Notifications",
      icon: BellIcon,
      onClick: handleOpenNotificationsMenu,
      menu: (
        <NotificationsMenu
          anchorEl={notificationsAnchorEl}
          open={Boolean(notificationsAnchorEl)}
          onClose={handleCloseNotificationsMenu}
          data={notifications}
          mutate={mutate}
        />
      ),
    },
    ...(user?.role === 0
      ? [
          {
            label: "Admin Panel",
            icon: AdminPanelIcon,
            href: "/admin",
          },
        ]
      : []),
  ];

  const currentTab = tabs.find(
    (route) => route.href === location.pathname.toLowerCase()
  );

  const logout = () => {
    fireAuth.signOut();
    navigate("/");
  };
  return (
    <Box
      component={"header"}
      className={`${styles["header"]} ${styles["private-header"]}`}
      sx={{ height: "4rem", bgcolor: themeColors.indigo100 }}
    >
      <Link
        to="/"
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img
          src={LogoSrc}
          style={{
            height: "2rem",
            marginTop: ".4rem",
          }}
          alt="vogo-logo"
        />
      </Link>
      <div className={styles["right-side"]}>
        {userConfigured &&
          tabs.map(({ label, href, onClick, icon, menu = null, sx = {} }) => (
            <Box
              sx={{
                ":hover": {
                  textDecoration: "underline",
                },
                ".icon": {
                  height: { xs: "1.2rem", sm: "1.3rem" },
                },
                ...sx,
              }}
              key={label}
            >
              <Link
                to={href}
                onClick={onClick}
                className={
                  currentTab?.label === label ? styles["active-tab"] : ""
                }
              >
                <Box display="flex" alignItems="center" position="relative">
                  {React.createElement(icon, { className: "icon" })}
                  {label === "Notifications" && (
                    <Zoom in={thereIsUnreadNotification}>
                      <Box
                        sx={{
                          ...jsStyles.orangeGradientInverted,
                          flexShrink: 0,
                          width: ".6rem",
                          height: ".6rem",
                          borderRadius: 10,
                          position: "absolute",
                          border: "1px solid white",
                          boxSizing: "content-box",
                          top: 0,
                          right: 3,
                        }}
                      />
                    </Zoom>
                  )}
                </Box>
                <Typography
                  marginLeft={0.7}
                  sx={{ display: { xs: "none", sm: "inline" } }}
                >
                  {label}
                </Typography>
              </Link>
              {menu}
            </Box>
          ))}

        {user &&
          user.status === USER_STATUSES[0].value &&
          !location.pathname.startsWith("/signup/") && (
            <GradientButton
              label="Complete registration"
              onClick={() =>
                navigate(
                  `/signup/${user.role === 2 ? "npo" : "tech-professional"}`
                )
              }
            />
          )}

        <Box
          display={"flex"}
          alignItems="center"
          sx={{
            cursor: "pointer",
            ".arrow": {
              transition: ".2s transform",
            },
            ":hover": {
              ".arrow": {
                transform: "translateY(2px)",
              },
            },
          }}
          onClick={handleOpenUsernameMenu}
        >
          <AppAvatar user={user} />
          <KeyboardArrowDownIcon className="arrow" />
        </Box>
        <Menu
          anchorEl={usernameAnchorEl}
          open={Boolean(usernameAnchorEl)}
          onClose={handleCloseUsernameMenu}
          sx={{
            "& .MuiPaper-root": {
              ...jsStyles.cardBorderRadius,
              width: "8rem",
              "& .MuiMenuItem-root": {
                display: "flex",
                py: 1.5,
                justifyContent: "space-between",
              },
            },
          }}
        >
          {userConfigured && (
            <Box>
              <Link to="/my-profile" className="clean-link">
                <MenuItem onClick={handleCloseUsernameMenu}>
                  Profile
                  <PersonIcon />
                </MenuItem>
              </Link>
              <Divider variant="middle" />
            </Box>
          )}
          <MenuItem onClick={logout}>
            Logout
            <LogoutIcon />
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

export default PrivateHeader;
