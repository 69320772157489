import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header/components/Header";
import LoadingScreen from "./LoadingScreen";
import { WyContext } from "@weavy/uikit-react";
import { useChat } from "../hooks/useChat";
import { useUser } from "../context/user";
import { appFetch } from "../utils/fetch";

const getToken = async (refresh) => {
  var res = await appFetch(`/api/auth/get-weavy-token?refresh=${refresh}`);
  if (res.ok) return (await res.json()).access_token;
  throw new Error("Failed to retrieve token");
};

function LayoutForUsers() {
  const { weavyClient, setWeavyClient } = useChat();
  const { user } = useUser();

  useEffect(() => {
    if (user?.status === "approved" && !weavyClient) {
      //   let client = new WeavyClient({
      //     url: process.env.REACT_APP_WEAVY_URL,
      //     tokenFactory: getToken,
      //   });
      let client = {
        url: process.env.REACT_APP_WEAVY_URL,
        tokenFactory: getToken,
      };
      setWeavyClient(client);
    }
  }, [user, weavyClient]);

  return (
    <>
      <Header />
      {user ? (
        // <WeavyProvider
        //   client={weavyClient}
        //   options={{ enableCloudFiles: true, enableScrollbarDetection: true }}
        // >
        //   <main className="LayoutForUsers__main">
        //     <Outlet />
        //   </main>
        // </WeavyProvider>
        <WyContext
          url={process.env.REACT_APP_WEAVY_URL}
          tokenFactory={getToken}
          options={{ enableCloudFiles: true, enableScrollbarDetection: true }}
        >
          <main className="LayoutForUsers__main">
            <Outlet />
          </main>
        </WyContext>
      ) : (
        <Outlet />
      )}
      <LoadingScreen sx={{ zIndex: -10 }} />
    </>
  );
}

export default LayoutForUsers;
