import { useParams } from "react-router-dom";
import AppAvatar from "../../../components/AppAvatar";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useUserProfile from "../hooks/useUserProfile";
import LinkedinIcon from "../../../images/icons/linkedin.png";
import WebsiteIcon from "../../../images/icons/website.png";
import EmailIcon from "../../../images/icons/mail.png";
import { Box } from "@mui/system";
import jsStyles from "../../../styles/jsStyles";
import { themeColors } from "../../../utils/constant";
import { validateByRules } from "../../../utils/validators";
import React, { useEffect, useRef, useState } from "react";
import ProjectCard from "../../../components/ProjectCard";
import { useUser } from "../../../context/user";
import EditableProfilePicture from "../../../components/EditableProfilePicture";
import AppEditIcon from "../../../components/AppEditIcon";
import SaveIcon from "@mui/icons-material/Save";
import { decodeHtml } from "../../../utils/tools";
import SkillsList from "../../../components/SkillsList";
import EditableLinkItem from "./EditableLinkItem";

const UserProfile = ({ isMyProfile }) => {
  const { user: myUser, updateUser } = useUser();
  const { uid } = useParams();
  const { user, memberSince, role, finishedProjectsCount, projects } =
    useUserProfile(isMyProfile ? myUser : uid);
  const [editMode, setEditMode] = useState("");
  const isXs = useMediaQuery(`@media screen and (max-width: 600px)`);

  const links = {
    ...(user?.role === 1
      ? { githubURL: { icon: WebsiteIcon, label: "Github" } }
      : {}),
    linkedinURL: { icon: LinkedinIcon, label: "Linkedin" },
    email: { icon: EmailIcon, label: "Message" },
  };

  const npoLinks = { npoWebsite: { icon: WebsiteIcon, label: "Website" } };

  const dataForList = [
    {
      label: "Skills",
      fieldKey: "skills",
      value: user?.professionPrograms.map(({ name }) => name).join(", "),
      editable: true,
      userRole: 1,
    },
    { label: "Member since", value: memberSince },
    { label: "Finished projects", value: finishedProjectsCount },
  ];

  const inputRefs = {
    fullName: useRef(null),
    title: useRef(null),
    description: useRef(null),
    githubURL: useRef(null),
    linkedinURL: useRef(null),
    email: useRef(null),

    npoName: useRef(null),
    npoWebsite: useRef(null),
    npoDescription: useRef(null),
  };

  useEffect(() => {
    if (!editMode) return;
    setTimeout(() => {
      inputRefs[editMode]?.current.focus();
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const saveLinksHandler = async () => {
    try {
      const githubElement = inputRefs.githubURL.current;
      const linkedinElement = inputRefs.linkedinURL.current;
      const npoWebsiteElement = inputRefs.npoWebsite.current;

      const relevantElements =
        editMode === "links"
          ? [githubElement, linkedinElement]
          : [npoWebsiteElement];

      if (relevantElements.some((el) => el?.classList.contains("error")))
        return;

      const data =
        editMode === "links"
          ? {
              url: {
                ...(linkedinElement
                  ? { linkedin: linkedinElement.textContent }
                  : {}),
                ...(githubElement
                  ? { github: githubElement.textContent }
                  : {}),
              },
            }
          : { npoWebsite: npoWebsiteElement.textContent };

      await updateUser(data);

      setEditMode("");
    } catch (error) {
      console.error(error);
    }
  };

  const saveDetailsHandler = async () => {
    try {
      const fields = ["fullName", "title", "description"];

      const data = Object.entries(inputRefs).reduce((prev, [key, ref]) => {
        return fields.includes(key) && ref.current
          ? { ...prev, ...JSON.parse(ref.current.dataset.inputValue) }
          : prev;
      }, {});

      await updateUser(data);
      setEditMode("");
    } catch (error) {}
  };

  const finishTutorialHandler = (tutorialType) => {
    const prevTutorials = user.finishedTutorials || [];
    updateUser(
      { finishedTutorials: prevTutorials.concat(tutorialType) },
      { disableSnack: true }
    );
  };

  const EditableStringField = ({
    fieldKey,
    isEditMode: editModeOn,
    getDataObject,
    checkValidation,
    defaultValue,
    placeholder,
    skeletonComponent,
    typographyProps = {},
    maxLength = null,
    withEditIcon = false,
    multiline = false,
    sx = {},
  }) => {
    const textValue = decodeHtml(defaultValue || user?.[fieldKey]);
    const inputRef = inputRefs[fieldKey];
    const isEditMode = editModeOn || editMode === fieldKey;

    const checkIsPlaceholderMode = () =>
      placeholder && !user[fieldKey] && !isEditMode;

    const getInputValue = (inputRef) =>
      multiline
        ? decodeHtml(inputRef.current.innerHTML)
            .replace(/<div>(?<innertext>.+?)<\/div>/g, "\n$<innertext>")
            .replace(/<.+?>/g, "")
        : inputRef.current.textContent;

    const storeValueInDataAttribute = (inputValue) => {
      const data = getDataObject
        ? getDataObject(inputValue)
        : {
            [fieldKey]: inputValue,
          };
      inputRef.current.dataset.inputValue = JSON.stringify(data);
    };
    const getStoredValueFromAttribue = () =>
      JSON.parse(inputRef.current.dataset.inputValue);

    const onSubmit = async (ev) => {
      ev?.preventDefault();
      if (inputRef.current.classList.contains("error")) return;

      const data = getStoredValueFromAttribue();
      await updateUser(data);
      setEditMode("");
      if (inputRef.current.textContent === "") {
        inputRef.current.textContent = placeholder;
        inputRef.current.classList.add("placeholder");
      }
    };
    const startEditing = () => {
      setEditMode(fieldKey);
      if (checkIsPlaceholderMode()) {
        inputRef.current.textContent = "";
        inputRef.current.classList.remove("placeholder");
      }
    };

    useEffect(() => {
      inputRef.current && storeValueInDataAttribute(getInputValue(inputRef));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return user ? (
      <Box
        component="form"
        display="flex"
        alignItems={!multiline ? "center" : "start"}
        gap={1}
        onSubmit={onSubmit}
        position="relative"
        sx={sx}
      >
        {withEditIcon &&
          isMyProfile &&
          (isEditMode ? (
            <IconButton color="secondary" type="submit">
              <SaveIcon />
            </IconButton>
          ) : (
            <AppEditIcon onClick={startEditing} />
          ))}
        <Typography
          p={0.5}
          whiteSpace="pre-line"
          display={!multiline ? "flex" : ""}
          suppressContentEditableWarning={true}
          contentEditable={isMyProfile && isEditMode}
          ref={inputRef}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              if (!multiline) onSubmit();
            }
          }}
          onInput={(ev) => {
            if (checkValidation) {
              const inputValue = getInputValue(inputRef);
              const errorMessage = checkValidation(inputValue);
              // update character counter
              if (maxLength) {
                const chractersCount = inputValue.length;
                inputRef.current.nextElementSibling.textContent = `${chractersCount}/${maxLength}`;
              }
              // update error className
              if (errorMessage) inputRef.current.classList.add("error");
              else inputRef.current.classList.remove("error");
              // store value
              storeValueInDataAttribute(inputValue);
            }
          }}
          className={checkIsPlaceholderMode() ? "placeholder" : ""}
          sx={{
            width: 1,
            "&.placeholder": { color: themeColors.main + "55" },
            ...(isMyProfile && isEditMode
              ? {
                  outline: `1px ${themeColors.indigo300} solid`,
                  outlineColor: themeColors.orangeGradientEnd,
                  borderRadius: 1,
                  "&.error": {
                    outline: `1px red solid`,
                  },
                }
              : {}),
          }}
          {...typographyProps}
        >
          {textValue || (isMyProfile && !isEditMode ? placeholder : "")}
        </Typography>
        {/* changes inside onInput event */}
        <Typography
          sx={{
            position: "absolute",
            top: "100%",
            right: 0,
            color: themeColors.indigo500,
          }}
          className="chracters-counter"
        />
      </Box>
    ) : (
      skeletonComponent
    );
  };
  // console.log(user);
  return (
    <Container maxWidth="lg">
      <Stack mt={2.5} mb={4}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "center", sm: "stretch" }}
          bgcolor={themeColors.indigo100}
          minHeight={360}
          sx={{ ...jsStyles.cardBorderRadius }}
          py={4}
          px={{ xs: 3, sm: 6 }}
          gap={4}
          mb={4}
        >
          <Box mb={{ xs: 3, sm: 0 }}>
            {isMyProfile ? (
              <Tooltip
                PopperProps={{
                  sx: {
                    ".MuiTooltip-tooltip": {
                      backgroundColor: themeColors.indigo500,
                      borderRadius: jsStyles.cardBorderRadius,
                      py: 1,
                      px: 2,
                    },
                  },
                }}
                title={
                  <Stack>
                    <Typography color={"white"}>
                      Creating a good profile helps you connect, build trust,
                      stand out, and may also increase your chances of being
                      accepted to projects.
                    </Typography>
                    <Button
                      size="small"
                      sx={{ alignSelf: "flex-end", mt: 1 }}
                      color="themeWhite"
                      onClick={() => finishTutorialHandler("profile-picture")}
                    >
                      OK
                    </Button>
                  </Stack>
                }
                arrow
                open={!user.finishedTutorials?.includes("profile-picture")}
                placement={isXs ? "bottom" : "bottom-start"}
              >
                <Box
                  className="flex-center"
                  width="fit-content"
                  sx={{ mr: { sm: 2 } }}
                >
                  <EditableProfilePicture size={{ xs: 90, sm: 100 }} />
                </Box>
              </Tooltip>
            ) : (
              <AppAvatar user={user} size={{ xs: 90, sm: 100 }} />
            )}
          </Box>
          <Stack maxWidth={600} width={1}>
            <Box display={"flex"} alignItems="center">
              <EditableStringField
                isEditMode={editMode === "user-details"}
                fieldKey="fullName"
                typographyProps={{ variant: "h3" }}
                getDataObject={(value) => {
                  const words = value.split(" ");
                  const [firstName, lastName] = [
                    words[0] || "",
                    words.slice(1).join(" ") || "",
                  ];
                  return { firstName, lastName };
                }}
                checkValidation={(value) => {
                  const words = value.split(" ");
                  const [firstName, lastName] = [
                    words[0] || "",
                    words.slice(1).join(" ") || "",
                  ];
                  return (
                    validateByRules("required name", firstName) ||
                    validateByRules("required name", lastName)
                  );
                }}
                skeletonComponent={<Skeleton height={40} width={150} />}
              />

              {isMyProfile && (
                <Box ml="auto">
                  {editMode === "user-details" ? (
                    <IconButton color="secondary" onClick={saveDetailsHandler}>
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <AppEditIcon onClick={() => setEditMode("user-details")} />
                  )}
                </Box>
              )}
            </Box>
            <EditableStringField
              fieldKey="title"
              isEditMode={false}
              defaultValue={user?.title || role}
              typographyProps={{ variant: "h4", fontWeight: 400 }}
              checkValidation={(value) => {
                return validateByRules("required title", value);
              }}
              skeletonComponent={<Skeleton height={25} width={200} />}
              sx={{ mb: 2 }}
            />
            <EditableStringField
              fieldKey="description"
              isEditMode={editMode === "user-details"}
              maxLength={500}
              placeholder="Please share a few words about yourself so that other members in the community can get to know you better."
              checkValidation={(value) => {
                return validateByRules("description", value);
              }}
              multiline
              skeletonComponent={
                <Stack spacing={1} mt={5}>
                  <Skeleton width={300} />
                  <Skeleton width={300} />
                  <Skeleton width={300} />
                  <Skeleton width={300} />
                </Stack>
              }
              sx={{ mb: 2 }}
            />
            {/* links */}
            <Box
              display="flex"
              alignItems={"center"}
              flexWrap="wrap"
              gap={2}
              mt="auto"
              sx={{
                ".link": {
                  display: "flex",
                  alignItems: "center",
                  gap: ".3em",
                  textDecoration: "none",
                  p: 1,
                  ":hover": {
                    textDecoration: "underline",
                  },
                },
              }}
            >
              {Boolean(user) &&
                Object.keys(links)
                  .filter((linkType) => isMyProfile || user[linkType])
                  .map((linkType) => {
                    const type = "links";
                    return (
                      <EditableLinkItem
                        key={linkType}
                        user={user}
                        isEditMode={editMode === type}
                        linkType={linkType}
                        link={links[linkType]}
                        type={type}
                        ref={inputRefs[linkType]}
                      />
                    );
                  })}
              {isMyProfile && (
                <Box ml="auto">
                  {editMode === "links" ? (
                    <IconButton color="secondary" onClick={saveLinksHandler}>
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <AppEditIcon onClick={() => setEditMode("links")} />
                  )}
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
        {user?.role === 2 && (
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "center", sm: "stretch" }}
            // bgcolor={themeColors.indigo100}
            minHeight={360}
            sx={{ ...jsStyles.cardBorderRadius }}
            py={4}
            px={{ xs: 3, sm: 6 }}
            gap={4}
            mb={4}
          >
            <Box mb={{ xs: 3, sm: 0 }}>
              {isMyProfile ? (
                <Box
                  className="flex-center"
                  width="fit-content"
                  sx={{ mr: { sm: 2 } }}
                >
                  <EditableProfilePicture
                    isNpoLogo
                    size={{ xs: 90, sm: 100 }}
                  />
                </Box>
              ) : (
                <AppAvatar isNpoLogo user={user} size={{ xs: 90, sm: 100 }} />
              )}
            </Box>
            <Stack maxWidth={600} width={1}>
              <Box display={"flex"} alignItems="center">
                <EditableStringField
                  isEditMode={editMode === "npo-details"}
                  fieldKey="npoName"
                  typographyProps={{ variant: "h3" }}
                  checkValidation={(value) => {
                    return validateByRules("required title", value);
                  }}
                  skeletonComponent={<Skeleton height={40} width={150} />}
                />

                {isMyProfile && (
                  <Box ml="auto">
                    {editMode === "npo-details" ? (
                      <IconButton
                        color="secondary"
                        onClick={saveDetailsHandler}
                      >
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <AppEditIcon
                        onClick={() => setEditMode("npo-details")}
                      />
                    )}
                  </Box>
                )}
              </Box>
              <EditableStringField
                isEditMode={editMode === "npo-details"}
                maxLength={500}
                fieldKey="npoDescription"
                checkValidation={(value) => {
                  return validateByRules("description", value);
                }}
                multiline
                skeletonComponent={
                  <Stack spacing={1} mt={5}>
                    <Skeleton width={300} />
                    <Skeleton width={300} />
                    <Skeleton width={300} />
                    <Skeleton width={300} />
                  </Stack>
                }
                sx={{ mb: 2 }}
              />
              {/* npo - links */}
              <Box
                display="flex"
                alignItems={"center"}
                flexWrap="wrap"
                gap={2}
                mt="auto"
                sx={{
                  ".link": {
                    display: "flex",
                    alignItems: "center",
                    gap: ".3em",
                    textDecoration: "none",
                    p: 1,
                    ":hover": {
                      textDecoration: "underline",
                    },
                  },
                }}
              >
                {Boolean(user) &&
                  Object.keys(npoLinks)
                    .filter((linkType) => isMyProfile || user[linkType])
                    .map((linkType) => {
                      const type = "npo-links";
                      return (
                        <EditableLinkItem
                          key={linkType}
                          user={user}
                          isEditMode={editMode === type}
                          linkType={linkType}
                          link={npoLinks[linkType]}
                          type={type}
                          ref={inputRefs[linkType]}
                        />
                      );
                    })}
                {isMyProfile && (
                  <Box ml="auto">
                    {editMode === "npo-links" ? (
                      <IconButton color="secondary" onClick={saveLinksHandler}>
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <AppEditIcon onClick={() => setEditMode("npo-links")} />
                    )}
                  </Box>
                )}
              </Box>
            </Stack>
          </Box>
        )}
        {dataForList.map(({ label, value, editable, fieldKey, userRole }) => {
          if (userRole && user?.role !== userRole) return null;
          return !user || value || editable ? (
            <React.Fragment key={label}>
              <Divider />
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                height={{ xs: "auto", sm: "auto" }}
                alignItems={{ xs: "", sm: "center" }}
                gap={{ xs: 2, sm: 0 }}
                py={{ xs: 2, sm: 1 }}
              >
                <Typography fontWeight={700} width={200}>
                  {label}
                </Typography>
                {isMyProfile && editable ? (
                  fieldKey !== "skills" ? (
                    <EditableStringField
                      skeletonComponent={<Skeleton width={150} />}
                      fieldKey={fieldKey}
                      checkValidation={(value) => {
                        if (!value) return;
                        return validateByRules("name", value);
                      }}
                    />
                  ) : (
                    <Box display={"flex"} alignItems="center" gap={1.5}>
                      {/* <AppEditIcon
                        onClick={() =>
                          setEditMode(editMode !== fieldKey ? fieldKey : "")
                        }
                      /> */}
                      <SkillsList isEditMode />
                      {/* <SkillsList isEditMode={editMode === fieldKey} /> */}
                    </Box>
                  )
                ) : (
                  <Typography py={0.5}>{value}</Typography>
                )}
              </Box>
            </React.Fragment>
          ) : null;
        })}
        <Divider />

        {projects?.length ? (
          <Box mb={6}>
            <Typography variant="h3" mt={4} mb={2}>
              Recent projects
            </Typography>
            <Grid container spacing={2}>
              {projects.map((project) => (
                <Grid key={project._id} item xs={12} sm={6} md={4}>
                  <ProjectCard {...project} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : !projects ? (
          <Skeleton
            width={300}
            height={250}
            variant={"rounded"}
            sx={{ ...jsStyles.cardBorderRadius, my: 5 }}
          />
        ) : null}
      </Stack>
    </Container>
  );
};

export default UserProfile;
