import React, { useState } from "react";
import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validateByRules } from "../../../../utils/validators";
import { useUser } from "../../../../context/user";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { appFetch, swrFetcherNotAuth } from "../../../../utils/fetch";
import GoogleLoginButton from "../../../../components/auth/google-login-button/GoogleLoginButton";
import AppInput from "../../../../components/AppInput";
import GradientButton from "../../../../components/GradientButton";

const googleProvider = new GoogleAuthProvider();

function Step1() {
  const { setUser } = useUser();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [showInputErrors, setShowInputErrors] = useState(false);

  const signupHandler = async (ev, isGoogleSignin) => {
    ev.preventDefault();

    if (!isGoogleSignin && validateForm()) {
      setShowInputErrors(true);
      return;
    }

    setLoading(true);
    setServerError("");

    try {
      const auth = getAuth();
      let credential, payload;

      if (isGoogleSignin) {
        const result = await signInWithPopup(auth, googleProvider);
        credential = GoogleAuthProvider.credentialFromResult(result);
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
        checkEmailValidation();
      }

      const currentUser = auth.currentUser;
      const googleDisplayName = currentUser?.displayName || "";

      payload = {
        fullName: isGoogleSignin
          ? googleDisplayName
          : `${firstName} ${lastName}`,
        ...(isGoogleSignin ? {} : { firstName, lastName }),
      };

      const res = await appFetch("/api/auth/signup", {
        method: "POST",
        body: JSON.stringify(payload),
      });

      const res2 = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify({
          firstName,
          lastName,
        }),
      });

      if (!res.ok) {
        const data = await res.json();
        throw new Error(data?.error?.msg || "Signup failed");
      }
      //Check for second error in updating user name
      if (!res2.ok) {
        const data2 = await res2.json();
        throw new Error(data2?.error?.msg || "Failed updating user details.");
      }

      const data = await res.json();
      setUser(data.user);
    } catch (err) {
      console.error("Signup error:", err.message);
      if (err.code === "auth/email-already-in-use") {
        setServerError("Email already in use.");
      } else if (
        ![
          "auth/popup-closed-by-user",
          "auth/cancelled-popup-request",
        ].includes(err.code)
      ) {
        setServerError(err.message);
      }

      const currentUser = getAuth().currentUser;
      if (currentUser) {
        try {
          await deleteUser(currentUser);
          console.log("User deleted due to error");
        } catch (deleteError) {
          console.error("Failed to delete user:", deleteError.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const hasErrors =
      validateByRules("required name", firstName) ||
      validateByRules("required name", lastName) ||
      validateByRules("email", email) ||
      validateByRules("password", password);
    return hasErrors;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const checkEmailValidation = async () => {
    try {
      const res = await swrFetcherNotAuth("/api/auth/check-email", {
        method: "POST",
        body: JSON.stringify({ email }),
      });

      if (res && !res.ok) {
        const data = await res.json();
        throw new Error(data.errorMessage || "Server error");
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Stack
      component={"form"}
      spacing={2}
      alignItems="center"
      width={{ xs: 1, sm: 450 }}
    >
      <AppInput
        label="First Name"
        name="firstName"
        value={firstName}
        setValue={setFirstName}
        validationRules={"required name"}
        showError={showInputErrors}
      />
      <AppInput
        label="Last Name"
        name="lastName"
        value={lastName}
        setValue={setLastName}
        validationRules={"required name"}
        showError={showInputErrors}
      />
      <AppInput
        label="Email"
        name="email"
        value={email}
        setValue={setEmail}
        validationRules={"email required"}
        showError={showInputErrors}
      />
      <AppInput
        label="Password"
        value={password}
        name="password"
        setValue={setPassword}
        validationRules={"password required"}
        showError={showInputErrors}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography color="error" height="1.5em">
        {serverError}
      </Typography>

      <GradientButton
        label="Next"
        onClick={(ev) => signupHandler(ev, false)}
        type="submit"
        loading={loading}
        sx={{ mb: 1, minWidth: "13.2rem" }}
      />
      <GoogleLoginButton
        loading={loading}
        label="Sign up with google"
        onClick={(ev) => signupHandler(ev, true)}
        type="submit"
      />
    </Stack>
  );
}

export default Step1;
