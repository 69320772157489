import React, { createContext, useContext, useState, useEffect } from "react";
import { appFetch } from "../utils/fetch";
import { onAuthStateChanged } from "firebase/auth";
import { fireAuth } from "../plugins/firebase";
import { useProjectsStatus } from "./projectsStatus";
import GenericSnackbar from "../components/GenericSnackbar";
import { getFullName } from "../utils/tools";
import { useChat } from "../hooks/useChat";

const UserContext = createContext({
  user: null,
  setUser: null,
  updateUser: null,
});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(undefined); // undefined for loading, null for empty
  const { weavyClient, setWeavyClient } = useChat();
  const { updateProjectsStatus } = useProjectsStatus();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [infoSnackbarOpen, setInfoSnackbarOpen] = useState(false);

  const onCloseSuccessSnackbar = () => setSuccessSnackbarOpen(false);
  const onCloseErrorSnackbar = () => setErrorSnackbarOpen(false);
  const onCloseInfoSnackbar = () => setInfoSnackbarOpen(false);

  const formatUser = (user) => {
    if (!user) return user;
    user.fullName = getFullName(user);
    return user;
  };

  const setUserHandler = (user) => setUser(formatUser(user));

  const updateUser = async (form, options = { disableSnack: false }) => {
    try {
      //   setLoading(true);
      setErrorSnackbarOpen(false);
      const res = await appFetch("/api/user", {
        method: "PUT",
        body: JSON.stringify(form),
      });
      if (!res.ok) {
        setUser(null);
        return;
      }
      const data = await res.json();
      setUserHandler(data.user);
      !options.disableSnack && setSuccessSnackbarOpen(true);
    } catch (err) {
      setErrorSnackbarOpen(true);
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    onAuthStateChanged(fireAuth, async (user) => {
      if (user) {
        const res = await appFetch("/api/auth/login");
        if (!res.ok) {
          weavyClient?.destroy();
          setWeavyClient(null);
          setUser(null);
          return;
        }
        const data = await res.json();
        setUserHandler(data.user);
        updateProjectsStatus(data.projectsStatus);
      } else {
        setUser(null);
        // User is signed out
      }
      console.log("I was called");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: setUserHandler,
        updateUser,
      }}
    >
      {children}
      <GenericSnackbar
        open={successSnackbarOpen}
        onClose={onCloseSuccessSnackbar}
        message={"Details saved"}
        type="success"
      />
      <GenericSnackbar
        open={errorSnackbarOpen}
        onClose={onCloseErrorSnackbar}
        type="error"
      />
    </UserContext.Provider>
  );
};

export function useUser() {
  return useContext(UserContext);
}
