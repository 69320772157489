import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AppTabs from "../../components/AppTabs";
import FQASummrayItem from "../../components/FQASummrayItem";
import GradientButton from "../../components/GradientButton";
import GradientDivider from "../../components/GradientDivider";
import GradientTypography from "../../components/GradientTypography";
import { themeColors, themeMeasurements } from "../../utils/constant";
import CaseStudyVideo from "./CaseStudyVideo";
import { useUser } from "../../context/user";
// import NPOBenefitArticle from "./NPOBenefitArticle";
import TechJuniorBenefitArticle from "./TechJuniorBenefitArticle";
import { FAQ_DATA } from "./data";
// import NPOImage1 from "./images/expand-influence.png";
// import NPOImage2 from "./images/payroll.png";
// import NPOImage3 from "./images/people-group.svg";
import TechJuniorImage1 from "./images/tech-junior-1.png";
import TechJuniorImage2 from "./images/tech-junior-2.png";
import TechJuniorImage3 from "./images/tech-junior-3.png";
import { AuthUserButton } from "./components/AuthUserButton";

const WelcomePage = () => {
  const [FAQType, setFAQType] = useState("tech professionals");
  const [openedFAQIndex, setOpenedFAQIndex] = useState(null);
  const navigate = useNavigate();
  const mdAndUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { user } = useUser();
  // const goToJoinUsPage = () => navigate("/join-us");
  const goToTechSignupPage = () => navigate("/signup");
  const goToProfilePage = () => navigate("/my-profile");

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column" gap={10}>
        {/*  SECTION 1 - Welcome */}

        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "start", md: "center" }}
          gap={3}
          flexGrow={1}
          sx={{
            background: `linear-gradient(119.07deg, ${themeColors.indigo300} 0%, ${themeColors.indigo100} 69.91%)`,
            borderRadius: themeMeasurements.borderRadiusBase,
            px: { xs: 2.5, sm: 5, lg: 10 },
            pt: { xs: 4, sm: 7, md: 0 },
            pb: 0,
            maxHeight: { xs: "auto", md: 550 },
          }}
        >
          <Box py={4} maxWidth={545}>
            <Typography variant="h1">Welcome to</Typography>
            <Typography variant="h1">vogo community</Typography>
            <Typography
              fontSize={{ sx: "1rem", md: "1.3rem" }}
              fontWeight={500}
              mt={3}
            >
              The ultimate platform for tech professionals who want to make a
              difference in the world.
              <br />
              our network connects passionate developers, designers, and
              engineers with nonprofit organizations in need of innovative
              solutions.
            </Typography>
            <AuthUserButton />
          </Box>
          <img
            src={require("../../images/homepage-primary.png")}
            alt="vogo-graphics"
            style={{
              flexGrow: 1,
              maxWidth: mdAndUp ? "min(48%,70vh)" : "100%",
              padding: mdAndUp ? 0 : "0 1rem",
              maxHeight: 500,
              objectFit: "contain",
            }}
          />
        </Box>

        <Container maxWidth="lg">
          <Box display="flex" flexDirection="column" gap={8}>
            {/*  SECTION 2 - Case studies */}
            <Box mb={8}>
              <div id="Case-studies-anchor">
                <GradientTypography mb={6}>Case studies</GradientTypography>
              </div>
              {/* CASE 1 */}
              <Box display="flex" gap={5} mb={6}>
                <CaseStudyVideo videoFilename="case-study-1.mp4" />
                {mdAndUp && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="end"
                    maxWidth={mdAndUp ? "19rem" : "auto"}
                  >
                    <Typography variant="h3" mb={2}>
                      Luda - Marketing Website
                    </Typography>
                    <Typography>
                      We designed and developed Luda official marketing
                      website. The website increases awareness leading to more
                      volunteers and traffic in the coffee shop.
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* CASE 2 */}
              <Box display="flex" flexDirection="row-reverse" gap={5}>
                <CaseStudyVideo videoFilename="case-study-2.mp4" />
                {mdAndUp && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    maxWidth={mdAndUp ? "19rem" : "auto"}
                  >
                    <Typography variant="h3" mb={2}>
                      LASOVA - Volunteer Management System
                    </Typography>
                    <Typography>
                      We developed a volunteer management and monitoring system
                      to easily control the volunteers and their activities.
                      The system improved the work processes and increased
                      efficiency.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* SECTION 3 - For tech juniors */}
            <Box>
              {/* Titles */}
              <Box display="flex" flexDirection="column" mb={7}>
                <div>
                  <GradientTypography
                    variant="h3"
                    id="for-tech-juniors-proffesionals-anchor"
                  >
                    For tech proffesionals
                  </GradientTypography>
                </div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="end"
                  mt={3.2}
                  mb={4.5}
                >
                  <Typography variant="h2" maxWidth={600}>
                    Gain the professional experience you need by building real
                    products 
                  </Typography>
                  {mdAndUp && (
                    <GradientDivider sx={{ maxWidth: 340, mb: 2 }} />
                  )}
                </Box>
                <Typography maxWidth={400}>
                  Join us and team up with product managers, developers and
                  UI/UX designers to develop real products for non-profit
                  organizations.
                </Typography>
              </Box>
              {/* Articles */}
              <Grid
                container
                rowSpacing={5}
                columnSpacing={{ xs: 3, md: 5, lg: 10 }}
                mb={9}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  display="flex"
                  justifyContent="center"
                >
                  <TechJuniorBenefitArticle
                    title="Accelerate your career "
                    description="Grow your career and enrich your CV with hands-on experience."
                    iconSrc={TechJuniorImage1}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  display="flex"
                  justifyContent="center"
                >
                  <TechJuniorBenefitArticle
                    title="Create networking opportunities"
                    description="Connect and collaborate with peers and mentors from the industry."
                    iconSrc={TechJuniorImage2}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  display="flex"
                  justifyContent="center"
                >
                  <TechJuniorBenefitArticle
                    title="Enjoy a flexible environment"
                    description="Choose your projects per your interests and work remotely from anywhere."
                    iconSrc={TechJuniorImage3}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center">
                <AuthUserButton
                  loggedInLabel={
                    user?.status === "approved"
                      ? "FIND PROJECTS"
                      : "CHECK YOUR PROFILE"
                  }
                  loggedInRedirect={
                    user?.status === "approved" ? "/projects" : "/my-profile"
                  }
                />
              </Box>
            </Box>
          </Box>
        </Container>
        {/* SECTION 4 - For NPOs */}
        {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={3}
          flexGrow={1}
          sx={{
            background: `linear-gradient(292.35deg, ${themeColors.indigo300} 0%, ${themeColors.indigo100} 80.58%)`,
            borderRadius: themeMeasurements.borderRadiusBase,
            px: { xs: 2, sm: 5, lg: 7 },
            py: { xs: 4, sm: 7, md: 9 },
          }}
        >
          <Container maxWidth="lg">
            Titles
            <Box display="flex" flexDirection="column" mb={7}>
              <div>
                <GradientTypography variant="h3" id="For-NPOs-anchor">
                  For NPOs
                </GradientTypography>
              </div>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="end"
                mt={3.2}
                mb={4.5}
              >
                <Typography variant="h2" maxWidth={800}>
                  Empower your association with technology and innovation 
                </Typography>
                {mdAndUp && <GradientDivider sx={{ maxWidth: 340, mb: 1.8 }} />}
              </Box>
              <Typography maxWidth={390}>
                Design and develop with us technological solution that will
                boost your organiztion to the next level.
              </Typography>
            </Box>
            Articles
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, md: 5, lg: 10 }}
              mb={{ xs: 4, md: 10 }}
            >
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent="space-between"
              >
                <NPOBenefitArticle
                  title="Develop tailor-made solutions"
                  description="Enjoy a dedicated volunteering team developing tailored solutions for your unique needs"
                  iconSrc={NPOImage1}
                />
              </Grid>
              <Grid item xs={12} md={4} display="flex" justifyContent="center">
                <NPOBenefitArticle
                  title="Improve efficiency and cost reduction"
                  description="With the right technological solutions that will streamline your processes"
                  iconSrc={NPOImage2}
                />
              </Grid>
              <Grid item xs={12} md={4} display="flex" justifyContent="center">
                <NPOBenefitArticle
                  title="Leverage the power of community"
                  description="Ask, explore and learn from vogo community experts"
                  iconSrc={NPOImage3}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center">
              <GradientButton label="LEARN MORE" onClick={goToJoinUsPage} />
            </Box>
          </Container>
        </Box> */}
        <Container maxWidth="lg">
          {/* SECTION 5 - Participating NPOs */}
          {/* <GradientTypography mb={{xs:3,sm:6}}>Participating NPOs</GradientTypography>
          <NposCarousel containerSx={{mb:{xs:6,sm:14}}} /> */}
          {/* SECTION 6 - FAQ */}
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row-reverse" }}
            justifyContent="space-between"
            gap={8}
            mb={20}
          >
            {/* Titles */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: "start", sm: "end" }}
              gap={2}
              sx={{ textAlign: { xs: "left", md: "right", flexShrink: 0 } }}
            >
              <GradientTypography component="h2" id="FAQs-anchor">
                FAQ
              </GradientTypography>
              <Typography>Frequently asked questions.</Typography>
              {mdAndUp && <GradientDivider sx={{ mt: "auto" }} />}
            </Box>
            {/* Content */}
            <Box display="flex" flexDirection="column" gap={2}>
              <AppTabs
                tabs={["tech professionals"]}
                setTab={setFAQType}
                activeTab={FAQType}
                mb={{ xs: 1, sm: 4.8 }}
              />
              <Box display="flex" flexDirection="column" gap={1}>
                {FAQ_DATA[
                  FAQType === "tech professionals"
                    ? "techProfessionals"
                    : "npos"
                ].map((item, index) => {
                  const isOpen = openedFAQIndex === index;
                  return (
                    <FQASummrayItem
                      key={item.question}
                      title={item.question}
                      description={item.answer}
                      isOpen={isOpen}
                      onClick={() => setOpenedFAQIndex(isOpen ? null : index)}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default WelcomePage;
