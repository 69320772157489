import CheckIcon from "@mui/icons-material/Check";
import { Menu, MenuItem, Stack, Typography, Zoom } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import jsStyles from "../styles/jsStyles";
import AppAvatar from "./AppAvatar";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { appFetch } from "../utils/fetch";
import GradientButton from "./GradientButton";
dayjs.extend(relativeTime);

function NotificationsMenu({ data, mutate, anchorEl, open, onClose }) {
  useEffect(() => {
    if (!open) return;

    const unreadNotifications = data?.filter((i) => !i.read);
    if (unreadNotifications?.length) markAsRead(unreadNotifications);

    async function markAsRead(items) {
      await appFetch("/api/notification/mark-as-read", {
        method: "PUT",
        body: JSON.stringify({ ids: items.map((i) => i._id) }),
      });
      mutate();
    }
  }, [open]);

  const zeroNotifications = data && !data.length;

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(open)}
      onClose={onClose}
      sx={{
        maxHeight: "80vh",
        "& .MuiPaper-root": {
          ...jsStyles.cardBorderRadius,
          width: !zeroNotifications ? 350 : "auto",
        },
      }}
    >
      {data ? (
        !zeroNotifications ? (
          data.map((item) => {
            return (
              <NotificationItem
                key={item._id}
                onCloseMenu={onClose}
                mutate={mutate}
                {...item}
              />
            );
          })
        ) : (
          <MenuItem sx={{ px: 1, py: 0.5 }} disableTouchRipple>
            There are no notifications
          </MenuItem>
        )
      ) : null}
    </Menu>
  );
}

function NotificationItem({
  onCloseMenu,
  type,
  avatarType,
  relatedUser,
  title,
  content,
  read,
  createdAt,
  actions,
  actionTakenType,
  metadata,
  _id,

  mutate,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onClickItem = () => {
    if (type === "join-team-request") {
      navigate(`/profile/${relatedUser.uid}`);
    }
    if (type === "join-team-approved") {
      const project_id = metadata.projectId;
      navigate(`/project/${project_id}/status`);
    } else return;
    onCloseMenu();
  };

  const avatar =
    avatarType === "user" ? <AppAvatar user={relatedUser} /> : null;
  const timeAgoStr = dayjs(createdAt).fromNow();

  return (
    <MenuItem sx={{ px: 1, py: 0.5 }} onClick={onClickItem} disableTouchRipple>
      <Box display="flex" gap={1} width={1} sx={{ overflow: "hidden" }}>
        {avatar}
        <Stack maxWidth={1} minWidth={0}>
          <Box
            display={"flex"}
            alignItems="center"
            // gap={1}
            justifyContent="space-between"
          >
            <Typography fontWeight={600}>{title}</Typography>
            {/* Unread Dot */}
            <Zoom in={!read}>
              <Box
                sx={{
                  ...jsStyles.orangeGradient,
                  flexShrink: 0,
                  width: ".5em",
                  height: ".5em",
                  borderRadius: 10,
                }}
              />
            </Zoom>
          </Box>
          <Typography
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              whiteSpace: "normal",
              display: "-webkit-box",
            }}
          >
            {content}
          </Typography>
          <Typography variant="caption" color={"GrayText"}>
            {timeAgoStr}
          </Typography>

          {Boolean(actions?.length) &&
            (!actionTakenType ? (
              <Box display="flex" gap={1} mt={0.5}>
                {actions.map((item) => {
                  const onClickAction = async (ev) => {
                    ev.stopPropagation();
                    try {
                      setLoading(true);
                      const updateNotification = async () => {
                        await appFetch(
                          `/api/notification/${_id}/update-action`,
                          {
                            method: "PUT",
                            body: JSON.stringify({
                              actionTakenType: item.type,
                            }),
                          }
                        );
                      };
                      if (type === "join-team-request") {
                        const res = await appFetch(item.action.url, {
                          method: item.action.method,
                        });
                        if (!res.ok) throw new Error();
                        updateNotification();
                        mutate();
                      }
                      if (type === "join-team-approved") {
                        const project_id = metadata.projectId;

                        if (!project_id) throw new Error();
                        mutate();
                        updateNotification();
                        navigate(`/project/${project_id}/status`);
                      }
                    } catch (error) {
                      enqueueSnackbar("Error", { variant: "error" });
                    } finally {
                      setLoading(false);
                    }
                  };
                  return (
                    <GradientButton
                      key={item._id}
                      label={item.label}
                      inverted={item.styleVariant === "outlined"}
                      sx={{
                        height: "auto",
                        minWidth: "auto",
                        py: 0.5,
                        px: 2,
                        textTransform: "uppercase",
                      }}
                      labelProps={{ variant: "body2" }}
                      onClick={onClickAction}
                      loading={loading}
                    />
                  );
                })}
              </Box>
            ) : (
              <Box display={"flex"} alignItems="center" gap={0.5}>
                <CheckIcon sx={{ fontSize: "1rem" }} />
                <Typography color={"success.main"}>
                  {actionTakenType}
                  {actionTakenType !== "open-project" ? "d" : ""}
                </Typography>
              </Box>
            ))}
        </Stack>
      </Box>
    </MenuItem>
  );
}

export default NotificationsMenu;
