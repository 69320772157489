import { getAuth } from "firebase/auth";

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:5009";

export async function appFetch(url, options = {}) {
  const token = await getAuth().currentUser.getIdToken();
  return fetch(`${baseUrl}${url}`, {
    headers: { "Content-Type": "application/json", Authorization: token },
    ...options,
  });
}

export function swrFetcher(...args) {
  return appFetch(...args)
    .then((res) => res.json())
    .then((json) => json.data);
}
export function swrFetcherNotAuth(...args) {
  const [url, options] = args;
  return fetch(`${baseUrl}${url}`, {
    headers: { "Content-Type": "application/json" },
    ...options,
  })
    .then((res) => res.json())
    .then((json) => json.data);
}
export function swrFetcherNotAuth2(...args) {
  const [url, options] = args;
  return fetch(`${baseUrl}${url}`, {
    headers: { "Content-Type": "application/json" },
    ...options,
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.error("Fetch error:", err);
      throw err; // Propagate error to the calling function
    });
}
