import {
  Container,
  Typography,
  Box,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ProjectsCarousel from "./ProjectsCarousel";
import { useUser } from "../../../context/user";
import { getTextByValue } from "../../../utils/tools";
import { PROFESSION_ROLES } from "../../../data/lists";
import { useState } from "react";
import ProjectCard from "../../../components/ProjectCard";
import jsStyles from "../../../styles/jsStyles";
import { themeColors } from "../../../utils/constant";
import GradientButton from "../../../components/GradientButton";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../../data/config.json";
import { useProjectsStatus } from "../../../context/projectsStatus";

const ProjectsPage = ({ data, mutate, isMyProjectsPage = false }) => {
  const { user } = useUser();
  const { projectsStatus } = useProjectsStatus();
  const navigate = useNavigate();
  const isXs = useMediaQuery(`@media screen and (max-width: 500px)`);
  const [showAll, setShowAll] = useState(false);

  const newProjects = [];
  const notNewProjects = [];

  data?.forEach((project) => {
    const teamWithUserRoleRequired = project.team.find(
      ({ requirements, chosenUser }) =>
        !chosenUser && requirements.role === user.professionRole
    );
    if (teamWithUserRoleRequired) {
      if (project.status === "gathering-team") newProjects.push(project);
      else if (project.status !== "done") notNewProjects.push(project);
    }
  });

  const showAllProjects = () => {
    setShowAll(true);
  };

  const goToPublishProject = () => navigate("/create-project");

  const noProjectsForUserTechRole =
    data && !newProjects.length && !notNewProjects.length;

  return (
    <Container sx={{ mb: 8 }}>
      <Stack
        sx={{
          bgcolor: themeColors.indigo100,
          py: 3,
          px: 3,
          mt: 3,
          mx: "auto",
          width: { sm: 650, xs: 1 },
          alignItems: "center",
          textAlign: "center",
          borderRadius: jsStyles.cardBorderRadius,
        }}
      >
        <Typography mb={3} fontWeight={500}>
          You can create a custom project tailored specifically to your needs.
        </Typography>
        <GradientButton
          label={"CREATE NEW PROJECT"}
          inverted
          invertedBgcolor={themeColors.indigo100}
          onClick={goToPublishProject}
        />
      </Stack>
      {!showAll && !isMyProjectsPage && !noProjectsForUserTechRole ? (
        <Stack spacing={3} mx="auto" width="fit-content">
          <ProjectsSection
            data={newProjects}
            mutate={mutate}
            loading={data === undefined}
            label={
              <>
                New projects{isXs && <br />} for{" "}
                {getTextByValue(PROFESSION_ROLES, user.professionRole)}s
              </>
            }
            showAllProjects={showAllProjects}
          />
          <ProjectsSection
            data={notNewProjects}
            mutate={mutate}
            loading={data === undefined}
            label={<>Join a team{isXs && <br />} that has already started</>}
            showAllProjects={showAllProjects}
          />
        </Stack>
      ) : (
        <>
          <Box justifyContent="space-between" mt={6} mb={3}>
            <Typography variant="h3">
              {isMyProjectsPage ? "My Projects" : "All Projects"}
            </Typography>
          </Box>
          {data?.length ? (
            <Grid
              container
              spacing={2}
              mx="auto"
              maxWidth={{ xs: 1, sm: 640, md: 980 }}
              justifyContent="center"
            >
              {data
                ? data.map((project) => (
                    <Grid
                      key={project._id}
                      item
                      xs={11}
                      sm={6}
                      md={4}
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                      pl={"0 !important"}
                    >
                      <ProjectCard marketplaceMode {...project} />
                    </Grid>
                  ))
                : null}
            </Grid>
          ) : (
            <Typography ml={3}>No projects (yet!)</Typography>
          )}
        </>
      )}
    </Container>
  );
};

function ProjectsSection({ loading, data, mutate, showAllProjects, label }) {
  return loading || data?.length ? (
    <Box>
      <Stack width={1} mx="auto">
        <Box
          display={"flex"}
          alignItems={{ xs: "start", sm: "center" }}
          justifyContent="space-between"
          mt={6}
          mb={3}
          px={{ xs: "9vw", sm: 7 }}
          width={1}
        >
          <Typography variant="h3" fontWeight={500}>
            {label}
          </Typography>
          <Typography color="secondary">
            <Typography
              component={"span"}
              className="link"
              whiteSpace={"nowrap"}
              onClick={showAllProjects}
            >
              Show all
            </Typography>
          </Typography>
        </Box>
        <ProjectsCarousel loading={loading} projects={data} mutate={mutate} />
      </Stack>
    </Box>
  ) : null;
}

export default ProjectsPage;
