import {
  Card,
  Checkbox,
  Grid,
  MenuItem,
  Stack,
  CardContent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import AppSelect from "../../../../components/AppSelect";
import GradientButton from "../../../../components/GradientButton";
import { useUser } from "../../../../context/user";
import { appFetch } from "../../../../utils/fetch";
import { useNavigate } from "react-router-dom";

export function Step4() {
  const { user, setUser } = useUser();
  const navigate = useNavigate(); // Hook for navigation
  return (
    <Card
      sx={{
        maxWidth: 400,
        margin: "0 auto",
        textAlign: "center",
        borderRadius: 2,
        boxShadow: 3,
        padding: 3,
      }}
    >
      <CardContent>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Your Registration is Pending Approval
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Thank you for signing up! Your account requires manual approval. Our
          team is reviewing your details and will notify you once your account
          is approved.
        </Typography>
        <Box mt={3}>
          <GradientButton
            label="Back to Home"
            onClick={() => {
              window.location.href = "/"; // Adjust navigation as needed
            }}
            useSingleColor={true}
            singleColor="#007bff"
          />
        </Box>
      </CardContent>
    </Card>
  );
}
